import * as React from "react"
import { Link } from "gatsby"

import Video from '../images/video/paju-bg.mp4'
import Poster from '../images/video/paju-bg.jpg'

import { StaticImage } from "gatsby-plugin-image"
import RTLogo from '../images/rt-logo.svg'
import ContactLauri from "../components/ContactLauri";

import Header from '../components/Header'

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import FactoryTour from "../components/FactoryTour"
import SampleProjects from "../components/SampleProjects"


const IndexPage = () => {

  return (
    <Layout>
      <Seo title="Home" />

      <Header />
      <div className="relative">
        <video autoPlay muted loop className="h-96 md:h-128 w-full object-cover">
          <source src={Video} poster={Poster} type="video/mp4" />
        </video>

        <div className="absolute top-0 left-0 w-full h-full bg-welement-black/40 text-welement-white">

          <div className="m-8 sm:m-16 md:m-20">
            <div className="md:w-3/4">
              <h1 className="title-xs wel-mb-s">
                Offsite Construction Partner
              </h1>
              <p className="body-xl wel-mb-m">
                Welement is an offsite timber construction partner helping contractors, engineers & developers innovate the building industry.
              </p>

              <Link to="/solutions" className="btn btn-white">
                Solutions
              </Link>
            </div>
          </div>
        </div>

      </div>

      <div className="container-base">

        <div className="text-center md:w-3/4 mx-auto wel-py-l">
          <h2 className="title-xs">
            What We Do
          </h2>
          <p className="body-xl">
            Welement provides solutions for prefabricated timber frame elements
          </p>

        </div>


      </div>

      <div className="container mx-auto text-center wel-mb-l">

        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-16">
          <div className="text-center">
            <StaticImage
              src="../images/solutions/eramaja.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Private Houses"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Private Houses</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../images/solutions/ridaelamud.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Row Houses"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Row Houses</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../images/solutions/korter.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Apartment Houses"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Apartment Houses</p>
          </div>
          <div className="text-center">
            <StaticImage
              src="../images/solutions/fassaad.png"
              quality={100}
              formats={["auto", "webp", "avif"]}
              alt="Facade Systems"
              loading="eager"
              placeholder="blurred"
              className="w-full rounded wel-mb-s"
            />
            <p className="title-s">Facade Systems</p>
          </div>

        </div>

        <Link to="/solutions" className="btn btn-red">Explore Solutions</Link>


      </div>

      <FactoryTour />

      <div className="container-base">
        <div className="mx-auto text-center w-3/4">
          <img src={RTLogo} alt="" className="h-10 mx-auto mb-8" />

          <p className="body-l wel-mb-m">Welement is part of Rand &amp; Tuulberg Group that has over 23 years of experience in construction, real estate development and manufacturing of construction materials.</p>
          <Link to="/our-story" className="btn btn-black">Read Our Story</Link>

        </div>
      </div>

      <div className="bg-welement-pink">
        <SampleProjects />
      </div>

      <ContactLauri />


    </Layout>
  )

};

export default IndexPage;

